//Chat Grid
.chatGrid {
  display: flex;
  align-items: flex-start;
  height: 100%;

  .userBox {
    min-width: 370px;
    width: 370px;
    height: 100%;

    .ant-list-empty-text {
      height: calc(100vh - 151px);
    }
  }

  .chatBox {
    flex: 1;
  }
}

//User list items
.inbox-wrapper {
  border-right: 1px solid $paleGray;
  overflow: hidden;
  height: calc(100vh - 75px);

  .ant-list {
    div {
      overflow: hidden;
    }
  }

  .inboxChatHeader {
    border-bottom: 1px solid $paleGray;

    .titleWrapper {
      padding: 0px 16px 0px 0;
      min-height: 76px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 0;
        font-size: 26px;
      }
    }

    .ant-list-items {
      //Uselists ul class
      height: calc(100vh - 150px);
      overflow: auto;
    }
  }

  .ant-list-items {
    height: calc(100vh - 152px);
    overflow: auto;
  }
}

.chatAvatatWithStatus {
  position: relative;
  width: 50px;
  height: 50px;

  .status {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid $white;
    border-radius: 100%;

    &.green {
      background-color: $green;
    }

    &.grey {
      background-color: $lightGray;
    }
  }

  &:hover {
    .avatar-overlay {
      opacity: 1;
      visibility: visible;
    }

    // .avatar-overlay-two {
    //   opacity: 1;
    //   visibility: visible;
    // }
  }
}

.chatAvatatWithStatus.smallSize {
  width: 40px;
  height: 40px;

  .status {
    bottom: -1px;
    right: -1px;
    width: 14px;
    height: 14px;
  }
}

//Avatar Content
.avatar-overlay {
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 18px;
  width: 220px;
  position: absolute;
  right: auto;
  left: 39px;
  top: -30px;
  bottom: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
  z-index: 999999;

  .triangleToolTip {
    position: absolute;
    top: 18px;
    left: -7.8%;
  }

  //.statUserHoverPopup.position-top .triangleToolTip {
  //  transform: rotate(180deg);
  //  bottom: -12px; /* Adjust this value as needed */
  //  top: auto;
  //}

  .avatar-overlay-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .avatar-icon {
      border: 3px solid #f1f3f6;
      border-radius: 100%;
      margin-bottom: 13px;

      .ant-avatar-image {
        border: 0;
        background: unset;
      }
    }

    .userName {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .userTitle {
      color: #7f7f88;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .avatar-buttons {
    display: flex;
    flex-flow: column;
    margin-top: 12px;

    button {
      height: 30px;
      min-height: 32px;
      margin-bottom: 8px;
      line-height: 1;
      font-weight: 500;
      font-size: 12px;
      border-radius: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes showComponent {
  0% {
    opacity: 0;
    /* Start with opacity 0 (hidden) */
  }

  100% {
    opacity: 1;
    /* End with opacity 1 (fully visible) */
  }
}

.avatar-overlay-two {
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 18px;
  width: 220px;
  position: absolute;
  left: 30px;
  right: 0;
  // left: 25px;
  top: -35px;
  bottom: auto;
  // opacity: 0;
  // visibility: hidden;
  z-index: 3;
  animation: showComponent 0.4s ease-in-out;
  transition: 0.5s ease;

  .triangleToolTip {
    position: absolute;
    top: 18px;
    left: -7.8%;
  }

  .avatar-overlay-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .avatar-icon {
      border: 3px solid #f1f3f6;
      border-radius: 100%;
      margin-bottom: 13px;

      .ant-avatar-image {
        border: 0;
        background: unset;
      }
    }

    .userName {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .userTitle {
      color: #7f7f88;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .avatar-buttons {
    display: flex;
    flex-flow: column;
    margin-top: 12px;

    button {
      height: 30px;
      min-height: 32px;
      margin-bottom: 8px;
      line-height: 1;
      font-weight: 500;
      font-size: 12px;
      border-radius: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.profile-hover {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px;
  width: 220px;
  z-index: 3;
  animation: showComponent 0.4s ease-in-out;
  transition: 0.5s ease;

  .avatar-overlay-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .avatar-icon {
      border: 3px solid #f1f3f6;
      border-radius: 100%;
      margin-bottom: 13px;

      .ant-avatar-image {
        border: 0;
        background: unset;
      }
    }

    .userName {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .userTitle {
      color: #7f7f88;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .avatar-buttons {
    display: flex;
    flex-flow: column;
    margin-top: 12px;

    button {
      height: 30px;
      min-height: 32px;
      margin-bottom: 8px;
      line-height: 1;
      font-weight: 500;
      font-size: 12px;
      border-radius: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.messageHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .message-preview {
    white-space: nowrap;
    /* Prevent line wrapping */
    overflow: hidden;
    /* Hide overflowing content */
    text-overflow: ellipsis;
    /* Display ellipsis (...) at the end */
    min-width: 160px;
    width: 190px;
    color: #7f7f88;
    font-weight: 400;
    font-size: 12px;
  }

  .time-span {
    color: #7f7f88;
    font-weight: 400;
    font-size: 10px;
  }
}

.chatCard {
  padding-right: 16px;
  cursor: pointer;

  .ant-list-item-meta-title {
    // font-family: "SF Pro Regular", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: $black;
    margin-bottom: 0 !important;
    margin-top: 4px;
  }
}

.messageBox {
  display: flex;
  width: 100%;

  textarea {
    border: 0;
    flex: 1;
    text-align: left;
    align-content: flex-start;
    color: $black;
    font-weight: 400;
    font-size: 16px;
    // font-family: "SF Pro Regular";
    padding: 0 3rem 0 0.3rem;

    &::placeholder {
      color: $lightGray;
      font-size: 16px;
    }

    &:focus,
    &:active {
      border-color: unset;
      outline: none;
      box-shadow: none;
    }
  }

  input {
    border: 0;
    flex: 1;
    text-align: left;
    align-content: flex-start;
    color: $black;
    font-weight: 400;
    font-size: 15px;
    // font-family: "SF Pro Regular";
    padding: 0 3rem 0 0.3rem;

    &::placeholder {
      color: $lightGray;
    }

    &:focus,
    &:active {
      border-color: unset;
      outline: none;
      box-shadow: none;
    }
  }

  .chatSend {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 90%;
    right: 0;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $blueAccent;

    &:hover,
    &:active,
    &:focus {
      background-color: $blueAccent;
    }

    &.modalChatSend {
      &.btn-primary {
        min-width: 0;
        min-height: 0;
      }
    }
  }
}

// Chat Window box
.chatWrapperBox {
  height: 100%;

  .chat-window {
    .attachment-wrapper {
      height: 100px;
      overflow: auto;
    }

    .ant-input-disabled {
      background-color: transparent;
    }

    .mic-icon {
      margin-right: 60px;
      margin-top: 3px;
    }

    .chat-header {
      padding: 0px 52px 0px 16px;
      min-height: 77px;
      border-bottom: 1px solid $paleGray;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .chat-messages {
      height: calc(100vh - 250px);
      overflow: auto;
      margin: 0 0 10px 0px;

      &.with-file {
        height: calc(100vh - 340px);
      }

      .ant-list-item {
        border-block-end: unset;

        &.system-message {
          text-align: center;
          justify-content: center;

          .message {
            padding: 0;
            border-radius: 0;
            background: transparent;

            p {
              color: $gray;
            }
          }
        }

        .message-time {
          font-size: 12px;
          color: $gray;
        }

        .messageTimeWrapper {
          display: flex;
          flex-flow: row;
          align-items: center;
          width: 90%;
        }

        &.customer-message {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .message-time {
            margin-left: 7px;
          }

          a {
            color: $blueAccent;
          }

          .messageTimeWrapper {
            align-items: baseline;
            flex-direction: column;

            .user-name {
              font-weight: bold;
              font-size: 15px;
            }

            video.chat-video {
              border-radius: 8px;
            }
          }

          .message {
            // background: $bg;
            background: #ffffff;

            margin-left: 2.5px;

            p {
              color: $black;
            }
          }
        }

        &.admin-message {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-direction: row-reverse;

          a {
            color: $blueAccent;
          }

          .messageTimeWrapper {
            display: flex;
            // flex-flow: row-reverse;
            width: 90%;
            align-items: baseline;
            flex-direction: column;

            .user-name {
              font-weight: bold;
              font-size: 15px;
            }

            video.chat-video {
              border-radius: 8px;
            }
          }

          .message-time {
            margin-right: 7px;
          }

          .message {
            margin-right: 10px;
            margin-left: 2.5px;
            word-break: break-word;

          }
        }

        .message {
          // padding: 8px 16px;
          // background: $blueAccent;
          border-radius: 20px;
          // min-width: 200px;
          max-width: 740px;

          p {
            margin: 0;
            font-weight: 400;
            color: $black;
            font-size: 15px;
          }
        }
      }
    }

    .chatHolder {
      padding: 0 28px;
    }
  }
}

.chat-window-input {
  border: 1px solid $lightGray;
  border: 1px solid $lightGray;
  border-color: $lightGray;
  border-color: $lightGray;
  border-radius: 50px;
  border-radius: 8px;
  overflow: hidden;
  overflow: hidden;
  padding: 20px 10px;
  padding: 15px 15px;
  min-height: 56px;
  box-shadow: 5px 5px #eee;

  .chatSend {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #b0a5f4;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: $blueAccent !important;
    }
  }

  .chatSend.isInput {
    background-color: #7b68ee;
  }
}

.chat-input {
  border: 1px solid $lightGray;
  border-color: $lightGray;
  border-radius: 8px;
  overflow: hidden;
  padding: 15px 15px;
  min-height: 156px;
  height: 56px;

  &.disabled {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
      width: 100%;
      height: 100%;
    }
  }
}

.todayMarker {
  text-align: center;
  font-weight: bold;
}

.emoji-picker-wrapper {
  display: flex;
  align-items: center;

  .chatInput-icon {
    width: fit-content;
    margin-right: 8px;

    .chatInput-iconModal {
      margin-right: 12px;
      margin-left: 4px;
    }

    svg {
      path {
        fill: #ced0de;
      }

      &:hover {
        cursor: pointer;

        path {
          fill: #000;
        }
      }
    }
  }
}

.typingPara {
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  margin: 14px 0;
}

.userProfileModal {
  &.chatSendModal {
    .modal-body {
      padding-bottom: 50px;
      padding-top: 15px;
    }

    .modal-content {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .modal-body {
    min-height: auto;
    padding-bottom: 38px;
  }
}

//======================= Pills ======================
.selected-users {
  display: flex;

  .addMember {
    transition: 0.5s ease;

    &.transform {
      transform: rotateZ(138deg);
    }
  }
}

.ant-tag {
  display: flex;
  align-items: center;
  border-radius: 50px;
  width: fit-content;
  padding: 6px 10px 6px 6px;
  background: #f5f6f8;
  border: 0;

  .title {
    margin: 0 13px 0 12px;
    font-size: 15px;
  }
}

.chatSendMessageModal {
  .ant-select-selector {
    border-radius: 50px;
  }

  .ant-select-selection-item-content {
    .roundedIcon {
      display: none;
    }
  }

  .ant-select-selection-item {
    border-radius: 50px;
    width: fit-content;
    padding: 6px 10px 6px 6px;
    background: #f5f6f8;
    border: 0;
    height: auto;
    align-items: center;

    .title {
      font-size: 13px;
      display: none;
    }
  }
}

.userDropdown {
  transition: 0.5s ease;
  height: 0;
  overflow: hidden;
  opacity: 0;

  &.open {
    height: auto;
    opacity: 1;
    padding: 8px 8px;

    .ant-select-selector {
      padding: 8px 12px !important;
    }
  }
}

//======= New Chat =================
.newChat {
  .selectMemeber {
    display: flex;
    align-items: center;
    width: 100%;

    .ant-select-selection-search-input {
      padding: 10px 0 !important;
      height: 100% !important;
    }

    h4 {
      white-space: nowrap;
    }

    .selectMemberDropdown {
      margin: 0 20px 0 10px;
      width: 100%;

      &.ant-select {
        &:hover {
          border-color: #000;
        }
      }

      .ant-select-selector {
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        border-color: $lightGray;
        border-radius: 50px;

        &:hover {
          border-color: #000 !important;
        }

        .ant-select-selection-placeholder {
          // font-family: "SF Pro Bold";
          font-weight: 500;
        }
      }
    }
  }
}

.chat-member-select-dropdown {
  .ant-select-selection-item {
    margin-top: 29px;
  }
}

.picker {
  position: absolute !important;
  z-index: 9999;
  bottom: 60px;
  left: auto;
  right: auto;
  margin: 0;

  aside.EmojiPickerReact.epr-main {
    position: inherit !important;
  }
}

.unread-messages-circle {
  background-color: blueviolet;
  position: absolute;
  top: 5px;
  right: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.admin-message a.file-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    height: 3em;
    width: 3em;
  }
}

.customer-message a.file-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    height: 3em;
    width: 3em;
  }
}

.react-pdf__Document {
  height: 80px;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid;
}

.pdf-name {
  background-color: mediumslateblue;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
}

.pdf-name p {
  margin: 0;
}

.loader-chat {
  text-align: center;
}

.chat-winddow__messages-list {
  .ant-list-empty-text {
    display: none !important;
  }
}

.chatWrapperBox__newChat {
  padding: 0px 52px 0px 16px;
  min-height: 77px;
  border-bottom: 1px solid $paleGray;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .chat-window>.chat-header {
    border-bottom: none;
    min-height: 76px;
  }
}

.newChatDropdown .rc-virtual-list-holder {
  max-height: 280px !important;
}

.chatPreviewModal {
  width: unset !important;

  .ant-modal-content {
    padding: 0;
    border-radius: 12px !important;
  }

  .ant-modal-close {
    width: 28px;
    height: 28px;
    top: 12px;
    right: 14px;
    color: #fff;
    background-color: rgba($color: #000, $alpha: 0.9);
    border-radius: 50%;

    &:hover {
      color: rgba($color: #fff, $alpha: 0.8);
      background-color: rgba($color: #000, $alpha: 0.9);
    }
  }
}

.chatPreviewImage {
  width: auto;
  height: auto;
  min-width: 200px;
  max-width: 60vw;
  max-height: 80vh;
  border-radius: 12px;
}

.messageTimeWrapper .file-content {
  justify-content: flex-start !important;
  color: grey !important;

  .file-content-icon {
    flex-shrink: 0;
  }

  .file-content-name {
    color: #7b68ee;
    margin-left: 10px;
  }
}