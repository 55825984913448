.form-floating > .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
  font-size: 14px;
  // width: 805px;
  // &::after{
  //   content: "";
  //   width: 1080px;
  //   height: 30px;
  //   background-color: white;
  //   position: absolute;
  //   left: 8px;
  //   z-index: -1;
  //   top: 8px;
  // }
}

.custom-textarea {
  &.editTextArea {
    &.form-floating > label {
      overflow: unset;
    }

    &.form-floating > .form-control:not(:placeholder-shown) ~ label {
      width: 100%;

      &::after {
        content: "";
        width: 1045px;
        height: 30px;
        background-color: white;
        position: absolute;
        left: 8px;
        z-index: -1;
        top: 8px;

        @media screen and (max-width: 1366px) {
          width: 600px;
        }
      }
    }
  }

  &.form-floating > .form-control:not(:placeholder-shown) ~ label {
    opacity: 1;
    font-size: 14px;
    // width: 800px;

    &::after {
      content: "";
      width: 795px;
      height: 30px;
      background-color: white;
      position: absolute;
      left: 8px;
      z-index: -1;
      top: 8px;
    }
  }
}

.form-floating {
  &.floating {
    label {
      font-size: 14px;
      opacity: 1;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      color: $blueAccent;
    }

    &.black {
      input {
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;

        &::placeholder {
          color: $gray2;
        }
      }

      label {
        color: black;
        padding: 1rem 0.75rem;
      }
    }
  }

  // textarea {
  //   padding-top: 8% !important;
  // }
  // textarea + label {
  //   background: white;
  //   opacity: 1 !important;
  //   height: auto;
  //   transform: scale(0.85);
  //   width: 100%;
  //   margin-top: 12px;
  //   display: block;
  //   min-width: fit-content;
  //   max-width: fit-content;
  // }
  label {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: $gray2;
    transition: 0.2s ease;

    &:focus {
      font-size: 14px;
    }
  }

  .form-control {
    transition: 0.3s ease;

    &.hasError {
      border-color: red;
      transition: all 1s ease-in;

      // padding: 0 0.75rem;
      ~ label {
        color: red;
      }

      &:focus,
      &:hover {
        box-shadow: none;
        border-color: red;

        ~ label {
          color: red;
        }
      }
    }

    &:focus {
      ~ label {
        font-size: 14px;
      }
    }

    &:focus {
      border-color: $blueAccent;
      box-shadow: none;

      ~ label {
        color: $blueAccent;
        opacity: 1;
      }

      &:hover {
        border-color: $blueAccent;
      }
    }

    &:hover {
      box-shadow: none;
      // border-color: $blueAccent;
      border-color: #000;
      cursor: pointer;

      ~ label {
        // color: $blueAccent;
        // color: #000;
        opacity: 1;
      }
    }
  }
}

.inputIcon {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translate(0, -50%);

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: white;
    width: 50px;
    height: 50px;
    right: -6px;
    top: -12px;
    z-index: -1;
  }
}

.seperator {
  margin: 40px 0;
  border-color: $lightGray2;
}

.form-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: red;
  text-align: left;
  align-self: flex-end;
  display: flex;

  &.errorMsgLocation {
    position: absolute;
  }
}

.themeFormGroup {
  position: relative;

  .form-text {
    position: absolute;
    bottom: -19px;
    left: 0;
  }
}

//Add Location
.addLocation {
  .addLocBtn {
    text-align: right;
    font-weight: 600;
    color: $blueAccent;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    &.opacity {
      opacity: 0.5;
      pointer-events: none;
    }

    svg {
      margin-right: 8px;
      font-size: 20px;
    }
  }
}

//Password
.password-toggle-icon {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 18px;
  transform: translate(0, -50%);
  max-height: fit-content;

  &:hover {
    cursor: pointer;
  }
}

.form-control {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  height: 55px;
}

//Custom Label CSS
.customLabelGroup {
  position: relative;
  cursor: pointer;
  border: 1px solid $border;
  border-radius: 5px;

  &:hover {
    border: 1px solid $black;
  }

  &:focus-within {
    border: 1px solid $blueAccent;
  }

  .ant-select-selector {
    padding: 0 !important;
  }

  .form-control {
    border: none;
    height: 100%;

    height: 56px !important;
    min-height: auto !important;

    &:hover {
      border: none;
    }
  }

  .hasValue {
    .customLabel {
      color: #6e6e6eff;
      padding-top: 10px;
    }
  }

  .ant-select-open {
    .ant-select-selection-placeholder {
      color: $blueAccent !important;
    }
  }

  &.datePickerWrapper {
    .label {
      color: #6e6e6e;
      font-size: 12px;
      padding-top: 10px;
    }

    &.form-floating {
      .form-control {
        padding: 0 0.75rem;
      }
    }
  }

  .customLabel {
    font-size: 12px;
    color: $blueAccent;
    padding-bottom: 2px;

    &.addedColor {
      color: $blueAccent;
    }
  }

  &.form-floating {
    .form-control {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
      padding: 0;

      &.hasValue {
        padding: 0 0.75rem;
      }
    }
  }

  &.customDropdown .form-control,
  &.datePickerWrapper .form-control {
    padding: 0 !important;
    position: relative;
    transition: none !important;

    .ant-select-selector {
      transition: none !important;
    }

    .label,
    .customLabel {
      pointer-events: none;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 12px;
    }

    .customDatePicker {
      height: 100% !important;
      padding: 12px !important;
    }

    &.hasValue .customDatePicker,
    &.hasValue .ant-select-selector {
      padding: 28px 12px 12px 12px !important;
    }
  }

  .ant-select {
    width: 100%;
    height: 100% !important;
    position: unset !important;

    .ant-select-selector {
      border: 0;
      padding: 0;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none;
      }
    }

    &.ant-select-focused:where(
        .css-dev-only-do-not-override-1vtf12y
      ).ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      box-shadow: none;
    }

    .ant-select-selection-item {
      font-size: 15px;
      color: $black;
      font-weight: 400;

      .roundedIcon {
        display: none;
      }
    }

    .ant-select-selection-placeholder {
      // font-family: "SF Pro", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: $gray2;
      padding: 1rem 0.75rem;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translate(0, -50%);
    z-index: 0;
  }

  .customfontsize {
    &.ant-select {
      .ant-select-selection-item {
        font-size: 13px;
      }
    }
  }
}

.ant-picker-dropdown {
  z-index: 9999;
}

//Global dropdown menu
.ant-select-dropdown {
  position: relative;
  z-index: 9999;

  &.position-unset {
    position: unset;
    z-index: unset;
  }

  &.dollarSelectMenu {
    .ant-select-item-option {
      .ant-select-item-option-content {
        padding: 0;
      }
    }
  }

  &.ant-tree-select-dropdown {
    padding: 15px 27px;
  }

  .ant-select-item-option {
    padding: 0;

    .ant-select-item-option-content {
      // display: inline-flex;
      align-items: center;
      justify-content: space-between;
      // padding: 7px 26px;
      padding: 7px 10px;
    }

    &.ant-select-item-option-selected {
      .roundedIcon {
        position: relative;
        display: block;
        background: $blueAccent;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        box-shadow:
          0 0 0 3px #ffffff,
          0px 0px 0 4px $blueAccent;
        margin-right: 4px;
      }
    }

    &.ant-select-item-option-active,
    &.ant-select-item-option-selected {
      background: none;

      .ant-select-item-option-content {
        color: $blueAccent;
      }
    }
  }

  .ant-select-item-option-content {
    // font-family: "SF Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: $black;
  }
}

.smileyBox {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: white;
    width: 25px;
    height: 50px;
    right: -6px;
    top: -17px;
    z-index: -1;
  }
}

aside.EmojiPickerReact.epr-main {
  position: absolute !important;
  z-index: 9999;
  top: 80%;
  left: auto;
  right: 0;
  margin: 0;
}

textarea {
  resize: none;
  font-size: 13px;

  &.form-control {
    padding: 15px;

    &:active,
    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: none;
      border-color: $blueAccent;
    }
  }

  &::placeholder {
    color: $gray2;
    font-size: 13px;
  }
}

.ant-select-open .ant-select-selection {
  box-shadow: none;
  outline: none;
}

.form-control-dropdown {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}

input {
  &#floatingPassword {
    padding-right: 45px;
  }
}

//Safari

// input {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// input::webkit-clear-button {
//   display: none;
// }
// input::-webkit-search-cancel-button {
//   display: none;
// }

input::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden !important;
  pointer-events: none !important;
}

//For Mac OS
.ant-checkbox-checked {
  &:after {
    content: normal !important;
  }
}

.form-label-group {
  .form-control {
    transition: 0.3s ease;

    &.hasError {
      border-color: red;
      transition: all 1s ease-in;

      ~ label {
        color: red;
      }

      &:focus,
      &:hover {
        box-shadow: none;
        border-color: red;

        ~ label {
          color: red;
        }
      }
    }

    &:focus {
      border-color: $blueAccent;
      box-shadow: none;

      ~ label {
        color: $blueAccent;
        opacity: 1;
      }

      &:hover {
        border-color: $blueAccent;
      }
    }

    &:hover {
      box-shadow: none;
      // border-color: $blueAccent;
      border-color: #000;
      cursor: pointer;

      ~ label {
        // color: $blueAccent;
        // color: #000;
        opacity: 1;
      }
    }
  }
}

.custom-focus {
  transition: 0.3s ease;

  &.hasError {
    border-color: red;
    transition: all 1s ease-in;

    ~ label {
      color: red;
    }

    &:focus,
    &:hover {
      box-shadow: none;
      border-color: red;

      ~ label {
        color: red;
      }
    }
  }

  &:focus {
    border-color: $blueAccent;
    box-shadow: none;

    ~ label {
      color: $blueAccent;
      opacity: 1;
    }

    &:hover {
      border-color: $blueAccent;
    }
  }

  &:hover {
    box-shadow: none;
    // border-color: $blueAccent;
    border-color: #000;
    cursor: pointer;

    ~ label {
      // color: $blueAccent;
      // color: #000;
      opacity: 1;
    }
  }
}

.question_option {
  &.checkbox {
    .form-check-input {
      &::after {
        border-radius: 4px;
      }

      &:checked {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='11' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='13' height='13' rx='0' fill='%237B68EE' stroke='%237B68EE'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &:focus {
    border-color: $blueAccent;
    box-shadow: none;

    ~ label {
      color: $blueAccent;
      opacity: 1;
    }

    &:hover {
      border-color: $blueAccent;
    }
  }

  &:hover {
    box-shadow: none;
    // border-color: $blueAccent;
    border-color: #000;
    cursor: pointer;

    ~ label {
      // color: $blueAccent;
      // color: #000;
      opacity: 1;
    }
  }
}

.goal-modal-form {
  .ant-select-selector {
    height: auto !important;
  }
}

.img-box {
  border-radius: 4px;
  border: 1px solid #cfcfcf;
  overflow: hidden;

  .custom-padding {
    padding: 15px 15px 8px 15px;
  }

  .form-check-input {
    margin-top: 3px;
  }

  small {
    font-size: 13px;
    font-weight: 400;
    color: #cfcfcf;
  }

  .upper-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 131px;
    background-color: #f1f3f6;
  }
}

.form-check-label {
  display: block;
  width: 100%;

  span {
    font-size: 10px !important;
    color: $blueAccent !important;
    position: absolute;
    left: -72px;
    top: 21px;
  }
}

.form-check-input {
  margin-top: 20px;
  margin-right: 11px;
  width: 16px;
  height: 16px;
  position: relative;
  border: none;

  &::after {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    content: "";
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 1p;
    left: -4px;
    top: -4px;
  }

  &:checked {
    background-color: $blueAccent;
    border: 1px solid $blueAccent;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='11' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='13' height='13' rx='6.5' fill='%237B68EE' stroke='%237B68EE'/%3E%3C/svg%3E%0A");
  }

  width: 14px;
  height: 14px;
}

.emoji-or-star-wrapper {
  margin: 15px 0px 0px 0px;

  label {
    margin-right: 15px;
  }

  input[type="radio"] {
    display: none;

    &:checked {
      & + span {
        border: 1px solid $blueAccent;
      }
    }
  }

  span {
    border: 1px solid #cfcfcf;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.custom-focus-number {
  height: 60px;

  &:hover {
    border: 1px solid $black !important;
  }

  &:focus {
    border: 1px solid $blueAccent !important;
  }
}

.roundedIcon {
  &.check {
    position: relative;
    display: block;
    background: $blueAccent !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 100%;
    box-shadow:
      0 0 0 3px #ffffff,
      0px 0px 0 4px $blueAccent;
    margin-right: 4px;
  }
}

.customLabelGroupMr {
  .ant-select-arrow {
    margin-right: 10px;
  }
}

.globalHeader {
  .ant-select {
    &.custom-select-box {
      border-radius: 4px;
      border-color: #ebeced !important;
      @media screen and (max-width: 1280px) {
        display: none;
      }
    }
  }
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-right: 2rem;
}

.goal-modal-form {
  h5 {
    font-size: 15px;
  }

  h5.custom-mb-2 {
    margin-bottom: 6px;
    margin-top: 20px;
  }
}

.scoped-select {
  &.hasValue .customDatePicker,
  &.hasValue .ant-select-selector {
    padding: 0 !important;
  }

  .customLabelGroup {
    .form-control {
      &.hasValue {
        .ant-select {
          .ant-select-selector {
            padding: 28px 11px !important;
            display: flex;
            align-items: center;

            &:focus {
              box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            }
          }
        }
      }
    }
  }
}

.onboardscoped-select {
  &.hasValue .customDatePicker,
  &.hasValue .ant-select-selector {
    padding: 0 !important;
  }

  .customLabelGroup {
    .form-control {
      .ant-select {
        .ant-select-selector {
          padding: 28px 11px !important;
          display: flex;
          align-items: center;
          background: none;
        }
      }
    }
  }
}

.goal-scoped-select {
  &.hasValue .customDatePicker,
  &.hasValue .ant-select-selector {
    padding: 0 !important;
  }

  .customLabelGroup {
    .form-control {
      .ant-select {
        .ant-select-selector {
          padding: 12px !important;
          display: flex;
          align-items: center;
          background: none;
        }
      }
    }
  }
}

.event-scoped-date-picker.customLabelGroup {
  .form-control {
    padding: 0 0.75rem !important;

    .customDatePicker {
      padding: 0 !important;
    }

    .customLabel {
      position: static;
    }

    .ant-picker {
      height: auto !important;
    }
  }
}

.goal-scoped-date-picker {
  .customLabelGroup.datePickerWrapper .form-control.hasValue .customDatePicker {
    padding: 12px !important;
  }
}

.link-to-resource-label {
  font-size: 13px !important;
}
